import "./styles.scss"
// Change the file to be treated as a module by adding an export statement
export {};

// Declare global variables for TypeScript
declare global {
    interface Window {
        FS?: {
            event: (eventName: string, eventData: object) => void;
        };
        gtag?: (command: string, action: string, params: object) => void;
    }
}

// Theme switching functionality
document.addEventListener('DOMContentLoaded', function() {
    const toggleSwitch = document.querySelector('#checkbox') as HTMLInputElement;
    const currentTheme = localStorage.getItem('theme');

    if (currentTheme) {
        toggleSwitch.checked = false;
        if (currentTheme === 'dark') {
            toggleSwitch.checked = true;
            toggleSwitch.click();
        }
    }

    function switchTheme(e: Event) {
        const target = e.target as HTMLInputElement;
        if (target.checked) {
            document.documentElement.querySelector('body')?.classList.add('dark-theme');
            document.documentElement.querySelector('body')?.classList.remove('light-theme');

            document.documentElement.querySelector('.navbar-nav')?.classList.add('navbar-dark');
            document.documentElement.querySelector('.navbar-nav')?.classList.remove('navbar-light');

            document.documentElement.querySelector('.navbar-brand')?.classList.add('navbar-dark');
            document.documentElement.querySelector('.navbar-brand')?.classList.remove('navbar-light');

            document.documentElement.querySelectorAll('.text-muted, .carousel-item p').forEach(el => {
                el.classList.remove('text-light');
                el.classList.add('text-dark');
            });
            localStorage.setItem('theme', 'dark');
        } else {
            document.documentElement.querySelector('body')?.classList.add('light-theme');
            document.documentElement.querySelector('body')?.classList.remove('dark-theme');
            document.documentElement.querySelector('.navbar-nav')?.classList.add('navbar-light');
            document.documentElement.querySelector('.navbar-nav')?.classList.remove('navbar-dark');
            document.documentElement.querySelector('.navbar-brand')?.classList.remove('navbar-dark');
            document.documentElement.querySelector('.navbar-brand')?.classList.add('navbar-light');

            document.documentElement.querySelectorAll('.text-muted, .carousel-item p').forEach(el => {
                el.classList.remove('text-dark');
                el.classList.add('text-light');
            });
            localStorage.setItem('theme', 'light');
        }
    }

    toggleSwitch.addEventListener('change', switchTheme, false);
});


document.addEventListener('DOMContentLoaded', function() {
    const subscribeForm = document.getElementById('subscribeForm');
    if (subscribeForm) {
        subscribeForm.addEventListener('submit', function (e) {
            e.preventDefault();
            const emailInput = document.getElementById('subscribeEmail') as HTMLInputElement;
            const messageElement = document.getElementById('subscribeMessage');

            if (emailInput && messageElement) {
                const email = emailInput.value;

                fetch('/subscribe', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            messageElement.innerHTML = 'Thank you for subscribing!';
                            messageElement.style.color = 'green';
                        } else {
                            messageElement.innerHTML = 'An error occurred. Please try again.';
                            messageElement.style.color = 'red';
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        messageElement.innerHTML = 'An error occurred. Please try again.';
                        messageElement.style.color = 'red';
                    });
            }
        });
    }
});


// Function to send message to analytics
function sendMessageToAnalytics(message: string) {
    // Send message to Fullstory
    // @ts-ignore
    if (window.FS && typeof window.FS.event === 'function') {
        // @ts-ignore
        window.FS.event('Chat Message Sent', {
            message: message,
            timestamp: new Date().toISOString()
        });
    }

    // Send message to Google Analytics
    // @ts-ignore
    if (window.gtag && typeof window.gtag === 'function') {
        // @ts-ignore
        window?.gtag('event', 'Chat Message Sent', {
            'event_category': 'Landing Chat',
            'event_label': message,
            'value': 1
        });
    }
}
let conversationID : string = "";
// Chat functionality
document.addEventListener('DOMContentLoaded', function() {
    const chatForm = document.getElementById('chat-form') as HTMLFormElement;
    const userInput = document.getElementById('user-input') as HTMLInputElement;
    const chatMessages = document.getElementById('chat-messages') as HTMLDivElement;
    

    chatForm.addEventListener('submit', function(e: Event) {
        e.preventDefault();
        const submitButton = document.getElementById('submit-button') as HTMLButtonElement;
        // Limit sending to max 5 messages per conversation
        const messageCount = chatMessages.getElementsByClassName('message').length;
        if (messageCount >= 10) { // 10 because it includes both user and AI messages
            alert('You have reached the maximum number of messages for this conversation.');
            submitButton.disabled = true;
            e.preventDefault();
            return;
        }
        // Simple rate limiting
        let attempts = 0;
        const maxAttempts = 10;
        const baseDelay = 500;

        submitButton.disabled = true;
        const delay = attempts < maxAttempts ? baseDelay : baseDelay * Math.pow(2, attempts - maxAttempts);
        setTimeout(() => {
            submitButton.disabled = false;
            attempts++;
        }, delay);

        // Basic input sanitization
        const sanitizedInput = userInput.value.replace(/<[^>]*>?/gm, '');

        // Check for spam-like content
        if (sanitizedInput.length > 500 || sanitizedInput.length < 2 ||
            /\b(viagra|cialis|buy|cheap|free|offer|discount|limited time)\b/i.test(sanitizedInput) ||
            /(https?:\/\/[^\s]+)/g.test(sanitizedInput) ||
            /[^\x00-\x7F]+/.test(sanitizedInput) ||
            sanitizedInput.split(' ').some(word => word.length > 20)) {
            alert('Your message was flagged as potential spam. Please try again.');
            submitButton.disabled = true;
            e.preventDefault();
            return;
        }

        // If all checks pass, you can proceed with sending the message
        console.log('Sending message:', sanitizedInput);
        userInput.value = sanitizedInput;

        const message = userInput.value.trim();
        if (message) {
            addMessage('You', message);
            let aiResponseElement = addMessage('AI Assistant', '<div class="spinner-grow spinner-grow-sm text-secondary" role="status"><span class="visually-hidden">Loading...</span></div>');
            sendMessageToAnalytics(message);
            fetch('https://api.sofiabot.ai/guest/chat/events', {
                    method: 'POST',
                    headers: {
                        'accept': '*/*',
                        'accept-language': 'en-US,en;q=0.9',
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        message: message,
                        model: "llama3-8b",
                        conversationID: conversationID,
                        profileID: 0
                    })
                })
                .then(response => {
                    const reader = response.body!.getReader();
                    const decoder = new TextDecoder();
                    updateMessage(aiResponseElement, "", true);
                    function readStream(): Promise<void> {
                        return reader.read().then(({
                            done,
                            value
                        }) => {
                            if (done) {
                                return;
                            }
                            const chunk = decoder.decode(value, {
                                stream: true
                            });

                            const chunks = chunk.split('\n\n');
                            let delay = 0;
                            chunks.forEach((chunk) => {
                                if (!chunk) {
                                    return;
                                }
                                if (chunk.includes("[DONE]")) {
                                    setTimeout(() => {
                                        sendMessageToAnalytics(aiResponseElement.textContent!);
                                    }, delay);
                                    return;
                                }
                                console.log(chunk);
                                const data = JSON.parse(chunk.trim().replace("data: ", ""));
                                console.log(data);
                                if (data.content) {
                                    setTimeout(() => {
                                        updateMessage(aiResponseElement, data.content);
                                    }, delay);
                                    delay += Math.floor(Math.random() * (80 - 40 + 1)) + 40; // Add random delay between 40ms and 80ms for each chunk
                                } else if (data.id) {
                                    conversationID = data.id;
                                }
                            })

                            return readStream();
                        });
                    }

                    return readStream();
                })
                .catch(error => {
                    console.error('Error:', error);
                    updateMessage(aiResponseElement, 'Sorry, I encountered an error. Please try again.', true);
                });
            userInput.value = '';
        }
    });

    function addMessage(sender: string, message: string): HTMLSpanElement {
        const messageElement = document.createElement('div');
        messageElement.className = 'mb-2';
        messageElement.innerHTML = `<strong>${sender}:</strong> <span class="message-content">${message}</span>`;
        chatMessages.appendChild(messageElement);
        chatMessages.scrollTop = chatMessages.scrollHeight;
        return messageElement.querySelector('.message-content') as HTMLSpanElement;
    }

    function updateMessage(element: HTMLSpanElement, newContent: string, reset: boolean = false) {
        if (reset) {
            element.textContent = "";
        }
        element.textContent += newContent;
        chatMessages.scrollTop = chatMessages.scrollHeight;
    }
});

// Load more use cases functionality
document.addEventListener('DOMContentLoaded', function() {
    const loadMoreButton = document.getElementById('loadMoreCases') as HTMLButtonElement;
    const additionalCases = document.getElementById('additionalCases') as HTMLDivElement;

    loadMoreButton.addEventListener('click', function() {
        additionalCases.style.display = 'flex';
        this.style.display = 'none';
    });
});

// Newsletter subscription functionality
document.addEventListener('DOMContentLoaded', function() {
    const subscribeForm = document.getElementById('subscribeForm') as HTMLFormElement;
    const subscribeEmail = document.getElementById('subscribeEmail') as HTMLInputElement;
    const subscribeMessage = document.getElementById('subscribeMessage') as HTMLDivElement;

    subscribeForm.addEventListener('submit', function(e: Event) {
        e.preventDefault();
        const email = subscribeEmail.value;

        fetch('/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    subscribeMessage.innerHTML = 'Thank you for subscribing!';
                    subscribeMessage.style.color = 'green';
                } else {
                    subscribeMessage.innerHTML = 'An error occurred. Please try again.';
                    subscribeMessage.style.color = 'red';
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                subscribeMessage.innerHTML = 'An error occurred. Please try again.';
                subscribeMessage.style.color = 'red';
            });
    });
});

// Smooth scrolling functionality
document.addEventListener('DOMContentLoaded', function() {
  const navLinks = document.querySelectorAll('.navbar-nav .nav-link, footer .list-unstyled a[href^="#"]');
    const headerHeight = (document.querySelector('.navbar') as HTMLElement).offsetHeight;

    navLinks.forEach(link => {
        link.addEventListener('click', function(this: HTMLAnchorElement, e: Event) {
            e.preventDefault();
            const targetId = this.getAttribute('href')!.substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - headerHeight;
                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }
        });
    });

    // Autofocus on "Try It Now" input when navbar button is clicked
    const tryItNowButton = document.querySelector('.navbar-nav .nav-item a.try-it') as HTMLElement;
    const userInput = document.getElementById('user-input') as HTMLInputElement;

    if (tryItNowButton && userInput) {
        tryItNowButton.addEventListener('click', function(e) {
            e.preventDefault();
            setTimeout(() => {
                userInput.focus();
            }, 100); // Small delay to ensure smooth scrolling completes
        });
    }
});

document.addEventListener('DOMContentLoaded', function () {
  const cookieConsentBanner = document.getElementById('cookie-consent-banner') as HTMLElement;
  const acceptCookiesBtn = document.getElementById('accept-cookies') as HTMLElement;
  const rejectCookiesBtn = document.getElementById('reject-cookies') as HTMLElement;

  function setCookie(name: string, value: string, days: number): void {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function showCookieConsentBanner(): void {
    cookieConsentBanner.style.display = 'block';
  }

  function hideCookieConsentBanner(): void {
    cookieConsentBanner.style.display = 'none';
  }

  function handleCookieConsent(): void {
    if (getCookie('cookie_consent') === null) {
      showCookieConsentBanner();
    }
  }

  acceptCookiesBtn.addEventListener('click', function () {
    setCookie('cookie_consent', 'accepted', 365);
    hideCookieConsentBanner();
  });

  rejectCookiesBtn.addEventListener('click', function () {
    setCookie('cookie_consent', 'rejected', 365);
    hideCookieConsentBanner();
  });

  handleCookieConsent();
});

// Function to reset conversation
function resetConversation() {
    const chatMessages = document.getElementById('chat-messages') as HTMLDivElement;
    const userInput = document.getElementById('user-input') as HTMLInputElement;

    chatMessages.innerHTML = '';
    userInput.value = '';
    conversationID = "";
}

// Event listener for reset button
document.addEventListener('DOMContentLoaded', function() {
    const resetButton = document.getElementById('reset-conversation') as HTMLButtonElement;
    if (resetButton) {
        resetButton.addEventListener('click', resetConversation);
    }
});